// @mui material components
import { Card, CardContent, CardHeader } from '@mui/material'
import { borderRadius } from '@mui/system'

// Material Kit 2 Pro React components
import MKBox from 'components/MKBox'
import MKTypography from 'components/MKTypography'

function RDFeatureCard({ color, image, label, title, subtitle, description, action, width }) {
  return (
    <Card
      sx={({
        palette: { gradients },
        functions: { rgba, linearGradient },
        borders: { borderRadius },
      }) => ({
        borderRadius: '20px',
        width: width,
        display: 'grid',
      })}
    >
      <CardHeader 
        title={ 
          // <MKBox textAlign='center'>
            <MKTypography variant='h4' color='white'>{title}</MKTypography>
          // </MKBox>
        }        
        sx={{
          bgcolor: '#4e2766',
          borderRadius: '20px 20px 0px 0px',
          height: 66,
          textAlign: 'center'
        }}
      />
      
      <CardContent>
        <MKTypography
          variant='h6'
          color='text'
          mb={2}
        >
          {subtitle}
        </MKTypography>
        <MKTypography
          variant='body2'
          color='text'
          opacity={0.8}
          mb={3}
        >
          {description}
        </MKTypography>
      </CardContent>
    </Card>
  )
}

export default RDFeatureCard