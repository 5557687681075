import MKBox from 'components/MKBox'
import DefaultNavbar from 'examples/Navbars/DefaultNavbar'

// Routes
import routes from 'routes'

// Images
import logo from 'assets/images/logos/rd_logo_purple.png'

function RDNavbar() {
  return (
    <MKBox mt={2}>
      <DefaultNavbar 
        logo={ logo }
        brand={null}
        routes={ routes }
        action={{
          type: 'external',
          route: 'https://app.radixdash.com',
          label: 'Login',
          color: 'warning',
        }}
        sticky
      />
    </MKBox>
  )
}

export default RDNavbar