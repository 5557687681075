// @mui components
import { Card, Stack } from '@mui/material'

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from 'components/MKTypography'

// RadixDash components
import RDFeatureCard from 'components/RDCards/RDFeatureCard';
import RDFooter from 'components/RDFooter'
import RDNavbar from 'components/RDNavbar'

// Routes
import footerRoutes from 'footer.routes'

// Images
import bgImage from 'assets/images/radixdash-bg-02.jpg'

function Homepage() {
  return (
    <>
      <MKBox
        minHeight='65vh'
        width='100%'
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPositiion: 'top',
          display: 'grid',
          placeItems: 'top',
        }}
      >
        <RDNavbar />
        <MKBox
          width={{ xs: '80%', md: '60%' }}
          px={5}
          mb={4}
          mx='auto'
        >
          <MKTypography
            variant='h2'
            color='white'
            align='center'
          >
            Custom Data Analytics for Optimized Business Operations
          </MKTypography>
        </MKBox>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: 'saturate(200%) blur(30px',
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Stack 
          direction={{ xs: 'column', md: 'row'}}
          justifyContent='space-evenly'
          alignItems='stretch'
          spacing={{ xs: 4}}
          sx={{ mt: 3 }}
        >
          <RDFeatureCard 
            title='Custom Web Reports'
            subtitle='View your data your way...'
            description='RadixDash will create custom reports to meet your exact needs.'
            width={{ xs: '100%', md: '40%' }}
          />
          <RDFeatureCard 
            title='Consolidate Your Data'
            subtitle='All your data from multiple sources can be uploaded into our data warehouse...'
            description='Your custom reports can utilize data from any of your data souces automatically.'
            width={{ xs: '100%', md: '40%' }}
          /> 
        </Stack>
        <Stack
          direction={{ xs: 'column', md: 'row'}}
          justifyContent='space-evenly'
          spacing={{ xs: 4 }}
          sx={{ mt: 4 }}
        >
          <RDFeatureCard 
            title='Collaborate Globally'
            subtitle='Keep your team up-to-date with the latest data for your projects...'
            description='RadixDash ensures everyone is using the approved version of your Custom Reports'
            width={{ xs: '100%', md: '40%' }}
          />       
          <RDFeatureCard 
            title='Unlimited Users'
            subtitle='Share reports with your team or clients...'
            description='RadixDash does not limit the number of users you allow to view your reports.'
            width={{ xs: '100%', md: '40%' }}
          />
        </Stack>
        <Stack
          direction={{ xs: 'column', md: 'row'}}
          justifyContent='space-evenly'
          spacing={{ xs: 4 }}
          sx={{ mt: 4, mb: 4 }}
        >
          <RDFeatureCard 
            title='Scale Automatically'
            subtitle='The RadixDash platform automatically scales as the amount of data you use grows...'
            description='We can support as many reports and users as you need.'
            width={{ xs: '100%', md: '40%' }}
          />
          <RDFeatureCard 
            title='Professional Support'
            subtitle='RadixDash support is available to update or modify your Custom Reports...'
            description="Simply let us know what is needed and we'll get it handled quickly."
            width={{ xs: '100%', md: '40%' }}
          />
        </Stack>
        <MKBox
          mx='auto'
          mb={2}
          align='center'
        >
          <MKTypography
            variant='button'
          >
            For more information about RadixDash, contact michael@radixdash.com
          </MKTypography>
        </MKBox>
      </Card>
      <RDFooter content={footerRoutes} />
    </>
  )
}

export default Homepage