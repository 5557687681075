import { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'

// @mui material components
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'

// Material Kit 2 Pro React themes
import theme from 'assets/theme'

// Material Kit 2 Pro React routes
import routes from 'routes'

// RadixDash components
import Homepage from 'pages/Homepage'


export default function App() {
  const { pathname } = useLocation()

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
  }, [pathname])

  const getRoutes = (allRoutes) => 
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse) 
      }

      if(route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />
      }

      return null
    })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path='/' element={<Homepage />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </ThemeProvider>
  );
}


