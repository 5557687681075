// @mui material components
import { Container } from '@mui/material'

// Material Kit 2 Pro React components
import MKBox from 'components/MKBox'

// Material Kit 2 Pro React base styles
import typography from 'assets/theme/base/typography'

function RDFooter({ content }) {
  const { copyright } = content
  const { size } = typography

  return (
    <Container>
      <MKBox
        width='100%'
        display='flex'
        flexDirection={{
          xs: 'column',
          lg: 'row'
        }}
        sx={{
          mb: 2
        }}
      >
        <MKBox
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexWrap='wrap'
          color='text'
          fontSize={size.sm}
          mx='auto'
        >
          { copyright }
        </MKBox>
      </MKBox>
    </Container>
  )
}

export default RDFooter